import React, { useState } from 'react';

const StoreContext = React.createContext();
export const initialStore = {
  columnId: undefined,
  colData: {},
  scrolledToWhite: false,
  menu: {
    hasMenu: true,
    isMenuOpen: false,
    menuItems: {
      about: true,
      contact: {
        rwtv: {
          phone: '123',
          mail: 'rwtv',
        },
        commercial: {
          phone: '456',
          mail: 'commercial',
        },
        narrative: {
          phone: '789',
          mail: 'narrative',
        },
      },
    },
  },
};

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState(initialStore);
  return (
    <StoreContext.Provider value={[store, setStore]}>
      {' '}
      {children}{' '}
    </StoreContext.Provider>
  );
};

export default StoreContext;
