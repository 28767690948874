import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import StoreContext from './Context';

export default function Nav({ page }) {
  const [store, setStore] = useContext(StoreContext);

  const ToggleMenuButtonFunction = () => {
    setStore({
      ...store,
      menu: { ...store.menu, isMenuOpen: !store.menu.isMenuOpen },
    });
  };

  const scrolling = () => {
    if (window.scrollY > window.innerHeight - 50) {
      setStore({ ...store, scrolledToWhite: true });
    } else {
      setStore({ ...store, scrolledToWhite: false });
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrolling);
  }, []);

  const whitePages = [
    'about',
    'contact',
    'sponsor',
    'journal/',
    '/about',
    '/contact',
    '/commercial/',
    '/narrative/',
    '/rwtv/',
  ];

  const renderSocialMedia = () => {
    if (page.includes('rwtv')) {
      return (
        <SocialStyles>
          <ul>
            <li>
              <a href="https://www.facebook.com/rwtvofficial"> FACEBOOK </a>
            </li>
            <li>
              <a href="https://www.instagram.com/rwtv_official/"> INSTAGRAM </a>
            </li>
          </ul>
        </SocialStyles>
      );
    }
    return (
      <SocialStyles>
        <ul>
          <li>
            <a href="https://vimeo.com/rumblewood"> VIMEO </a>
          </li>
          <li>
            <a href="https://www.facebook.com/rumblewood"> FACEBOOK </a>
          </li>
          <li>
            <a href="https://www.instagram.com/rumblewoood/"> INSTAGRAM </a>
          </li>
        </ul>
      </SocialStyles>
    );
  };

  const isWhitePage = whitePages
    // eslint-disable-next-line array-callback-return
    .map((el) => {
      if (
        page.includes(el) &&
        (page.length > 12 || page.includes('rwtv/about'))
      ) {
        return el;
      }
    })
    .join('');

  return (
    <>
      <ToggleMenuButton
        isWhitePage={isWhitePage.length || store.scrolledToWhite}
        onClick={ToggleMenuButtonFunction}
      >
        {store?.menu?.isMenuOpen ? 'CLOSE' : 'MENU'}
      </ToggleMenuButton>
      <NavStyles isOpen={store?.menu?.isMenuOpen}>
        <NavItems onClick={ToggleMenuButtonFunction}>
          <ul>
            {page.includes('commercial') && (
              <>
                <li>
                  <Link to="/">Start</Link>
                </li>
                <li>
                  <Link to="/commercial">Home</Link>
                </li>
                <li>
                  <Link to="/commercial/about">About</Link>
                </li>
                <li>
                  <Link to="/commercial/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/commercial/journal">Journal</Link>
                </li>
                <li>
                  <Link to="/commercial/studio">Studio</Link>
                </li>
              </>
            )}
            {page.includes('narrative') && (
              <>
                <li>
                  <Link to="/">Start</Link>
                </li>
                <li>
                  <Link to="/narrative">Home</Link>
                </li>

                <li>
                  <Link to="/narrative/about">About</Link>
                </li>
                <li>
                  <Link to="/narrative/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/narrative/journal">Journal</Link>
                </li>
              </>
            )}
            {page.includes('rwtv') && (
              <>
                <li>
                  <Link to="/">Start</Link>
                </li>
                <li>
                  <Link to="/rwtv">Home</Link>
                </li>
                <li>
                  <Link to="/rwtv/about">About</Link>
                </li>
                <li>
                  <Link to="/rwtv/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/rwtv/sponsor">Sponsor</Link>
                </li>
                <li>
                  <Link to="https://www.youtube.com/channel/UCGjk2n-wzdjWunxqxCq0iVg">
                    YouTube
                  </Link>
                </li>
              </>
            )}
          </ul>
        </NavItems>

        {renderSocialMedia()}
      </NavStyles>
    </>
  );
}

const ToggleMenuButton = styled.button`
  letter-spacing: 0.83px;
  z-index: 100;
  position: fixed;
  top: 25px;
  right: 25px;
  color: ${(p) => (p.isWhitePage ? '#212121' : '#F2F1E9')};
  font-size: 20px;
  cursor: pointer;
  font-weight: bold !important;
  background: transparent;
  border: none;
  font-family: RalewayBold;
  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 800px) {
    top: 15px;
    right: 15px;
  }
`;

const NavStyles = styled.nav`
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: ${(p) => (p.isOpen ? 20 : 0)}%;
  transition: 1s;
  background-color: var(--background);
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  overflow-x: hidden;
  box-shadow: rgb(0 0 0 / 19%) 2px 15px 20px;

  @media screen and (max-width: 800px) {
    width: ${(p) => (p.isOpen ? 40 : 0)}%;
  }

  ul {
    margin: 80px 0 0 0;

    li {
      list-style-type: none;
      border-bottom: 1px solid var(--grey);
      padding: 10px 0 5px 0;
    }

    li a {
      color: var(--black);
      font-size: 25px;
      text-decoration: none;
      list-style: none;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      font-family: PlayfairDisplayItalic;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.03px;
      }
    }
  }
`;

const NavItems = styled.section`
  width: 100%;
`;

const SocialStyles = styled.section`
  display: flex;
  align-self: flex-end;

  ul {
    padding: 0 25px 25px;
    margin-top: 20px;
    @media screen and (max-width: 600px) {
      margin-bottom: 100px;
    }
    li {
      border: none;
      padding: 3px 0;

      a {
        line-height: 15px;
        text-transform: uppercase;
        font-size: 13px;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        letter-spacing: 0.02px;
        font-family: RalewayBold;
      }
    }
  }
`;
