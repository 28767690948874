import React from 'react';
import Layout from './src/components/Layout';
import { StoreProvider } from './src/components/Context.jsx';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}> {element}</Layout>;
}

export const wrapRootElement = ({ element }) => (
  <StoreProvider> {element} </StoreProvider>
);
