import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --grey: #D8D8D8;
    --darkGrey: #363636;
    --black: #212121;
    --white: #F2F1E9;
    --background: #F5F4ED;
  }
  html {
    background-size: 450px;
    background-attachment: fixed;
    font-size: 10px;
    background-color: var(--background)
  }

  body {
    font-size: 2rem;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }


  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--white) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  img {
    max-width: 100%;
  }

`;

export default GlobalStyles;
