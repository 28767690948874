import React from 'react';
import 'normalize.css';
import Nav from './Nav';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import { Footer } from './Footer';

export default function Layout({ children }) {
  const pagesWithMenu = [
    'commercial',
    'narrative',
    'rwtv',
    'contact',
    'about',
    'sponsor',
    'journal',
  ];
  const aMenuPage =
    pagesWithMenu.filter((page) => children[1].props.path.includes(page))
      .length > 0;

  return (
    <>
      <GlobalStyles />
      <Typography />
      <div>
        <div>
          {aMenuPage && <Nav page={children[1].props.path} />}

          {children}
          {children[1].props.path !== '/' && <Footer />}
        </div>
      </div>
    </>
  );
}
