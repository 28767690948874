import { createGlobalStyle } from 'styled-components';
import RalewayBold from '../assets/fonts/RalewayBold.ttf';
import PlayfairDisplayItalic from '../assets/fonts/PlayfairDisplayItalic.ttf';
import RalewayMedium from '../assets/fonts/RalewayMedium.ttf';
import RalewayRegular from '../assets/fonts/RalewayRegular.ttf';
import PlayfairDisplayBold from '../assets/fonts/PlayfairDisplayBold.ttf';

const Typography = createGlobalStyle`
  @font-face {
    font-family: RalewayBold;
    src: url(${RalewayBold});
  }
  @font-face {
    font-family: PlayfairDisplayItalic;
    src: url(${PlayfairDisplayItalic});
  }
  @font-face {
    font-family: PlayfairDisplayBold;
    src: url(${PlayfairDisplayBold});
  }
  @font-face {
    font-family: RalewayMedium;
    src: url(${RalewayMedium});
  }
  @font-face {
    font-family: RalewayRegular;
    src: url(${RalewayRegular});
  }
  html {
    font-family: RalewayRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
    font-family: RalewayRegular;
  }


  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    letter-spacing: 0.5px;
    font-family: PlayfairDisplayItalic;
    font-stretch: normal;
    font-weight: normal;
  }

  strong {
    letter-spacing: 0.5px;
  }

  a {

    text-decoration: none;
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
    font-family: RalewayMedium;
    color: var(--black)
  }

`;

export default Typography;
