import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

export const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        sanityStoreSettings {
          copyright
        }
      }
    `}
    render={(data) => (
      <StyledContainer>
        {' '}
        <p> {data.sanityStoreSettings.copyright} </p>
      </StyledContainer>
    )}
  />
);

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  p {
    font-size: 10px;
    line-height: 25px;
    letter-spacing: 0.01px;
    color: var(--darkGrey);
    font-weight: normal;
    text-align: bottom;
  }
`;
